import React from "react";

LeftFormRightImage.MainHeader = ({ children }) => {
  return <h3>{children}</h3>;
};

LeftFormRightImage.SubHeader = ({ children }) => {
  return <>{children}</>;
};
LeftFormRightImage.Header = ({ children }) => {
  return <>{children}</>;
};
LeftFormRightImage.Link = ({ children }) => {
  return <>{children}</>;
};

export default function LeftFormRightImage({ children, image, altImage }) {
  // console.log("test", children, image, altImage);
  return (
    <section className="features_content_left pl-20-tage mt-0 landing_page slack-teams-sec-top trial_page_img integrations_page_form">
      <div className="container">
        <div className="row">
          <div className="col-md-6 feature_page_content_left">{children}</div>
          <div className="col-md-6 feature_page_img_right ">
            <img src={image} alt={altImage ? altImage : "trial"} />
          </div>
        </div>
      </div>
    </section>
  );
}
