import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import Container from "../../components/chat-channel/Container";

import Layout from "../../components/chat-channel/Layout";
import LeftImageWithContent from "../../components/chat-channel/WithBgLeftImage";
import ArrowRightICon from "../../components/common/Icons";
import TitleAndMetaTags from "../../components/common/TitleAndHeader";
import IconsHeader from "../../components/integration/IconsHeader";
import LeftFormRightImage from "../../components/chat-channel/LeftFormRightImage";
import KeyResources from "../../components/common/KeyResources";
import { RequestForm } from "../../components/form";
import { JumpstartSlider } from "../../components/integration/JumpstartSlider";
import { useIntegrations } from "../../components/useIntegrations";
const topImage = require("../../assets/img/integration/slack/slack_logo.png");

const section_one = require("../../assets/img/integration/slack/slack_header.png");
const section_two = require("../../assets/img/integration/slack/slack_sec_1.png");
const section_three = require("../../assets/img/integration/slack/slack_sec_2.png");
const section_four = require("../../assets/img/integration/slack/slack_sec_3.png");

export const blogImg = require("../../assets/img/key-resources/bc_1_4.png");
export const whitepaperImg = require("../../assets/img/key-resources/wp_1_4.png");
export const videoImg = require("../../assets/img/key-resources/vc_1_4.png");

export const slack = require("../../assets/images/icons-web/slack.svg");
export const sharepoint = require("../../assets/images/icons-web/sharepoint.svg");
export const jira = require("../../assets/images/icons-web/jira.svg");

export const icon1 = require("../../assets/img/feature/sec_1_bg.png");
export const icon2 = require("../../assets/img/feature/sec_2_bg.png");
export const icon3 = require("../../assets/img/feature/sec_3_bg.png");
export const icon4 = require("../../assets/img/feature/sec_4_bg.png");

const actionImg = require("../../assets/img/integration/action.png");

const resoucesData = [
  {
    image: blogImg,
    header:
      "Transform your Slack channel into an AI-driven digital help desk for your employees      ",
    url:
      "https://blog.workativ.com/transform-your-business-slack-channel-into-an-it-help-desk-for-your-employees-using-workativ-assistant/",
    link: "Read Blog",
    alt: "blog"
  },
  {
    image: whitepaperImg,
    header:
      "What’s the real value for business in transforming workplace support?",
    url: "/conversational-ai-platform/chatbot-whitepaper",
    link: "Read Whitepaper",
    alt: "Blog"
  },
  {
    image: videoImg,
    header: "How to Automate Tier 1 IT Support in Slack      ",
    url: "https://youtu.be/0MuSj4FPkyA",
    link: "Watch Video",
    alt: "videoImg"
  }
];

const featureContent = [
  {
    icon: icon1,
    header:"Slack Integration + Workflow Automation with Conversational AI Chatbot",
    content:
      "Automate common workplace tasks and auto-resolve repetitive issues on Slack using advanced chatbot and process automation.",
    link: null,
    image: section_one,
    toForm: true,  
    headerTop: true
  },
  {
    icon: icon2,
    header: "Slack Automation",
    content:
      "Workativ enables end-to-end workflow automation, so you don’t have to switch between apps to complete workplace tasks. Admins can easily unlock accounts, manage user access, and HR teams can onboard and offboard employees through our intelligent chatbot that works on Slack. Create automated workflows to share updates on Slack or send important messages to groups or the entire organization.",
    link: null,
    url: null,
    image: section_two
  },
  {
    icon: icon3,
    header: "Slack integrations",
    content:
      "Send approvals and notifications or resolve issues right from Slack with the Workativ Chatbot and Slack integration. Workativ’s chatbot platform integrates with popular applications like Zendesk, Jira, ServiceNow, Azure, Auth0, Box, and can connect them all to enable teams to create automated workflows to reduce the amount of manual work required on routine tasks and repetitive issues. If you’re on the hunt for software that enables Zendesk Slack integration or Jira Slack integration with zero coding, your search ends here.",
    link: "Applications Marketplace",
    url: "/conversational-ai-platform/marketplace",
    image: section_three
  },
  {
    icon: icon4,
    header: "Slack Chatbot",
    content:
      "Workativ does the heavy lifting to make it easy for you to create a Slack chatbot without any coding. Workativ’s Conversational AI + Process Automation in a single platform helps you quickly build an intelligent chatbot that combines AI technology and Automation to augment workplace tasks in hours. The chatbot on your Slack channel can quickly evolve into the helpdesk for simple repetitive issues, reducing the time spent on creating tickets and resolving issues, thereby vastly improving the employee experience.",
    link: null,
    url: null,
    image: section_four
  }
];

const SlackAndTeams = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);
const SlackIcons = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/slack.png")}
        alt="slack"
      />
      Slack
    </div>
  </div>
);
const TeamsIcons = () => (
  <div className="chat_channels_trial">
    <div className="chat_channels_trial_btn">
      <img
        src={require("../../assets/images/trial_landing/teams.png")}
        alt="teams"
      />
      Teams
    </div>
  </div>
);

export default function FeatureHomePage() {
  const appName = "Slack"
  const [isLoading, releatedApps, selecttedApp] = useIntegrations(appName)
  return (
    <>
      <TitleAndMetaTags
        title="Slack Chatbot Integration and Automations | Integrate Slack with Conversational AI platform | Workativ Assistant"
        description="Integrate Slack with Chatbot. Put your Helpdesk on Auto mode with Conversational AI and Automaton. Reinvent your workplace HR & IT support instantly."
        keywords={[]}
        ogImage={section_one}
        ogTitle="Slack Chatbot Integration and Automations | Integrate Slack with Conversational AI platform | Workativ Assistant"
        ogDescription="Integrate Slack with Chatbot. Put your Helpdesk on Auto mode with Conversational AI and Automaton. Reinvent your workplace HR & IT support instantly."
      />
      <Container>
      <Layout logoFor="ASSISTANT">
          <IconsHeader rightImage={topImage} altImage="slack">
            <IconsHeader.Header>Workativ Integrations</IconsHeader.Header>
            <IconsHeader.AppName>Slack</IconsHeader.AppName>
          </IconsHeader>
          <div className="integrations_wrapper_inner">

          {featureContent.map((feature, index) => {
              if (index % 2 == 0) {
                return (
                  <>
                    <LeftFormRightImage
                      image={feature.image}
                      altImage={feature.header}
                    >
                      {feature.toDisplayNew != undefined && (
                        <div className="coming_soon_wrapper">
                          <span className="coming_soon">Coming soon</span>
                        </div>
                      )}
                      <LeftFormRightImage.Header>
                        {feature.headerTop ? (
                          <h1>
                            <img
                              className="icon-header-left"
                              src={feature.icon}
                              alt="header-icon"
                            />
                            <span>{feature.header}</span>
                          </h1>
                        ) : (
                          <h2>
                            <img
                              className="icon-header-left"
                              src={feature.icon}
                              alt="header-icon"
                            />
                            <span>{feature.header}</span>
                          </h2>
                        )}
                      </LeftFormRightImage.Header>
                      <LeftFormRightImage.SubHeader>
                        <p
                          dangerouslySetInnerHTML={{ __html: feature.content }}
                        />
                      </LeftFormRightImage.SubHeader>
                      <LeftFormRightImage.Link>
                        {feature.link && (
                          <div className="card_link_landing">
                            <a href={feature.url} className="url_manipulation">
                              {feature.link}
                              <span className="arrow_svg_link">
                                <ArrowRightICon />
                              </span>
                            </a>
                          </div>
                        )}
                        {feature.toDisplaySlackTeamsIcons != undefined && (
                          <SlackAndTeams />
                        )}
                        {feature.toDisplaySlackIcons != undefined && (
                          <SlackIcons />
                        )}
                        {feature.toDisplayTeamsIcons != undefined && (
                          <TeamsIcons />
                        )}
                      </LeftFormRightImage.Link>
                      {feature.toForm != undefined && (
                        <RequestForm isFooterForm={false} />
                      )}{" "}
                    </LeftFormRightImage>
                  </>
                );
              }
              return (
                <LeftImageWithContent
                  image={feature.image}
                  altImage={feature.header}
                >
                  <LeftImageWithContent.HeaderIcon>
                    <h2>
                      <img
                        className="icon-header-left"
                        src={feature.icon}
                        alt="header-icon"
                      />
                      <span>{feature.header}</span>
                    </h2>
                  </LeftImageWithContent.HeaderIcon>
                  <LeftImageWithContent.SubHeader>
                    <p dangerouslySetInnerHTML={{ __html: feature.content }} />
                  </LeftImageWithContent.SubHeader>
                  <LeftImageWithContent.Link>
                    <div className="card_link_landing">
                      {feature.link && (
                        <a href={feature.url} className="url_manipulation">
                          {feature.link ? feature.link : "Learn more"}
                          <span className="arrow_svg_link">
                            <ArrowRightICon />
                          </span>
                        </a>
                      )}
                      {feature.toDisplaySlackTeamsIcons != undefined && (
                        <SlackAndTeams />
                      )}
                      {feature.toDisplaySlackIcons != undefined && (
                        <SlackIcons />
                      )}
                      {feature.toDisplayTeamsIcons != undefined && (
                        <TeamsIcons />
                      )}
                    </div>
                  </LeftImageWithContent.Link>
                </LeftImageWithContent>
              );
            })}
            </div>
                      <JumpstartSlider isLoading={isLoading} appName={selecttedApp.name} appIcon={selecttedApp.image} sliderData={releatedApps} title={`Jumpstart with popular ${selecttedApp.name} integrations`} />
           <KeyResources cards={resoucesData} />
          <RequestForm isFooterForm={true} />
        </Layout>
      </Container>
    </>
  );
}
