import React, { useState, useEffect } from 'react';
import * as $ from "jquery";

const axios = require("axios").default;
const replaceSpaceandtoLower = (val) => {
    return val.replace(/\s+/g, "-").toLowerCase();
};

function callApi(appName) {
    // const url = `http://localhost:2019/jumpstart/${appName}`;
    const url = `/jumpstart/${appName}`;
    const options = {
        url,
        method: "GET",
        headers: {
            Accept: "application/json",
            "Content-Type": "application/json;charset=UTF-8"
        }
    };
    return axios(options)
        .then(resp => resp.data).then(data => {
            if (data.selected) {
                return data
            }

            return { selected: { name: "N/A", image: "N/A" }, relatedApp: [] }
        })
        .catch(_ => {
            console.log("error")
            return { selected: { name: "N/A", image: "N/A" }, relatedApp: [] }
        });
}

export function useIntegrations(appName) {

    const [isLoading, setIsLoading] = useState(true)

    const [appListData, setAppListData] = useState({ relatedApp: [], selected: {} })

    // console.log("useIntegrations", appListData)
    useEffect(() => {
        callApi(replaceSpaceandtoLower(appName)).then(data => {
            setAppListData(data)
            setIsLoading(false)
            setTimeout(() => {
                $("#jumpstart-slider").slick({
                    dots: false,
                    infinite: true,
                    speed: 500,
                    slidesToShow: 4,
                    slidesToScroll: 1,
                    autoplay: false,
                    autoplaySpeed: 5000,
                    arrows: true,
                    responsive: [
                        {
                            breakpoint: 1600,
                            settings: {
                                slidesToShow: 3,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 1100,
                            settings: {
                                slidesToShow: 2,
                                slidesToScroll: 1
                            }
                        },
                        {
                            breakpoint: 600,
                            settings: {
                                slidesToShow: 1,
                                slidesToScroll: 1
                            }
                        }
                    ]
                })
            }, 0)
        })
    }, [])

    return [isLoading, appListData["relatedApp"], appListData["selected"]]
}