
import React from "react";
import { ArrowRightICon, PlusIcon } from "../../components/common/Icons";



const axios = require("axios").default;
if (typeof window !== `undefined`) {
  require("../../assets/js/slick.js");
  require("../../assets/styles/slick.css");
  require("../../assets/styles/slick-theme.css");
}

// current app name and icon should come from props
// 

const replaceSpaceandtoLower = (val) => {
  return val.replace(/\s+/g, "-").toLowerCase();
};

export const dynamicUrl = "/integration/";
export function JumpstartSlider(props) {

  const { title, appIcon, appName, sliderData, isLoading } = props;

  console.log("Jumpstart", appName, isLoading, sliderData)

  if (isLoading) {
    return (
      <section className="featured_content slider_two_apps">
        <div className="container">
          <div className="jumpstart_loader">
            <div className="loader_demo" id="loader_demo">
              <div className="loader"></div>
            </div>
          </div>
        </div>
      </section>
    )
  }
  
  if (sliderData.length == 0) {
    return null
  }

  return (
    <section className="featured_content slider_two_apps">
      <div className="container">
        <h4>{title}</h4>
        <div className="left-whitespace" />
        <div className="jumpstart-slider" id="jumpstart-slider">
          {sliderData.map((slider, i) => (
            <div className="cards_slider" >
              <div className="benefits-workplace-content cogni_workspace_content home_slider">
                <div className="integrations_card_slider ">
                  <div className="icons_wrapper_img">
                    <img
                      src={appIcon} alt={""}
                    />
                    <span>
                      <PlusIcon />
                    </span>
                    <img src={slider.image} alt={slider.name} />
                  </div>
                  <h5>
                    {appName}
                    <span>
                      <PlusIcon />
                    </span>
                    {slider.name}
                  </h5>
                </div>
                <p>
                  Build a new automation connecting {appName}{" "}
                  and {slider.name}
                </p>
                <div className="home_link">
                  <a
                    className="slider_span"
                    href={`${dynamicUrl}${replaceSpaceandtoLower(
                      appName
                    )}+${replaceSpaceandtoLower(slider.name)}`}
                    style={{ cursor: "pointer" }}
                  >
                    Check now
                  </a>
                  <span className="arrow_svg_link">
                    <ArrowRightICon />
                  </span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
}


