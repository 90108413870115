import React from "react";

IconsHeader.Header = ({ children }) => {
  return <h3 className="header_integrations">{children}</h3>;
};

IconsHeader.AppName = ({ children }) => {
  return <h3 className="header_integrations_name">{children}</h3>;
};

export default function IconsHeader({ children, rightImage, altImage }) {
  return (
    <section className="inegrations_header zoho_desk_bg">
      <div className="container">
        <div className="row">
          <div className="col-lg-7 col-md-12 col-sm-12 col-xs-12">
            <div className="integration_header_top">{children}</div>
          </div>
          <div className="col-lg-5 col-md-12 col-sm-12 col-xs-12 ">
            <div className="inegrations_header_right">
              <img src={rightImage} alt={altImage ? altImage : "apps"} />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
