import React from "react";

LeftImageWithContent.MainHeader = ({ children }) => {
  return <h3>{children}</h3>;
};

LeftImageWithContent.SubHeader = ({ children }) => {
  return <>{children}</>;
};
LeftImageWithContent.HeaderIcon = ({ children }) => {
  return <>{children}</>;
};
LeftImageWithContent.Link = ({ children }) => {
  return <>{children}</>;
};

export default function LeftImageWithContent({
  children,
  image,
  isFeature,
  altImage,
}) {
  return (
    <section className="features_img_left hr-itsm-padding-mobile pl-10-tage landing_page trial_page_img bg_trial_page">
      <div className="container">
        <div className="row">
          <div className="col-md-6 feature_page_img_right ">
            <img src={image} alt={altImage ? altImage : "trial"} />
          </div>
          <div className="col-md-6 feature_page_content_left">{children}</div>
        </div>
      </div>
    </section>
  );
}
